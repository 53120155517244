import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { Cart } from "../../components/cart/cart";
import "./galleriePhotos.scss";
import Spinner from "../../components/spinner/spinner";

interface Products {
	id: number;
	name: string;
	description: string;
	price: number;
	image_path: string;
	tags: string[];
}

export function GaleriePhotos() {
	const [products, setProducts] = useState<Products[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setShowButton(window.scrollY > 1500);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	useEffect(() => {
		setLoading(true);
		fetch(`${process.env.REACT_APP_API_URL}/api-candyshop/products`)
			.then((response) => response.json())
			.then((data) => {
				const shuffled = [...data].sort(() => Math.random() - 0.5);
				setProducts(shuffled);
				setLoading(false);
			})
			.catch((error) => {
				setError(error.message);
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <Spinner />;
	}

	if (error) {
		return <div className="error">Erreur : {error}</div>;
	}

	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop by stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.webp",
		logo: "https://candyshop-by-stess.fr/assets/icon.webp",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement candyshop by stess !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	return (
		<>
			<Helmet>
				<title>Galerie Photos | Candyshop By Stess</title>
				<meta
					name="description"
					content="Découvrez notre galerie de photos de délicieux produits Candyshop."
				/>
				<link
					rel="canonical"
					href="https://candyshop-by-stess.fr/gallerie-photos"
				/>
				<script type="application/ld+json">
					{JSON.stringify(structuredData)}
				</script>
			</Helmet>
			<NavLink
				to={`/`}
				className="homePage-link-back homePage-link-back-gallerie"
				aria-label="Retour à l'accueil"
			>
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="galleriePhotos page">
				<Cart />
				<h1>Photos</h1>
				<div className="gallerie-wrapper">
					<button
						className="scrollTop"
						title="Haut de page"
						aria-label="Haut de page"
						onClick={() => {
							sessionStorage.setItem("ScrollPosition", String(0));
							window.scrollTo({ top: 0, behavior: "smooth" });
						}}
						style={{ display: showButton ? "block" : "none" }}
					>
						<i className="fa-solid fa-arrow-up"></i>
					</button>

					{products.map((product) => (
						<div key={product.id}>
							<img
								src={`${process.env.REACT_APP_API_URL}/api-candyshop/${product.image_path}`}
								alt={`candyshop by stess | ${product.name}`}
							></img>
							<h3>{product.name}</h3>
						</div>
					))}
				</div>
			</div>
		</>
	);
}

export default GaleriePhotos;
