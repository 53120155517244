import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { clearCart, Login, Logout, TokenOff } from "../../redux/actions/action";
import {
	AuthActionTypes,
	RootState,
	UserState,
} from "../../redux/actions/typeAction";
import "./userPage.scss";

const predefinedTags = [
	"anniversaire",
	"naissance",
	"mariage",
	"baptême",
	"fete-des-meres",
	"saint-valentin",
	"chocolat",
	"chocolats",
	"chocolats-Page",
	"kinder",
	"bonbon",
	"vrac",
	"preparations",
	"gâteaux-Kinder",
	"gâteaux",
	"cookies",
	"sucre",
	"vegan",
	"halal",
	"assortiment",
	"vintage",
	"halloween",
	"pâques",
];

export function UserPage() {
	const user = useSelector((state: RootState) => state.user);
	const [newLastName, setNewLastName] = useState<string>(user.lastName);
	const [newFirstName, setNewFirstName] = useState<string>(user.firstName);
	const [newEmail, setNewEmail] = useState<string>(user.email);
	const [newAdresse, setNewAdresse] = useState<string>(user.adresse);
	const [newTel, setNewTel] = useState<string>(user.tel);
	const [newProductName, setNewProductName] = useState<string>("");
	const [newProductDescription, setNewProductDescription] =
		useState<string>("");
	const [newProductPrice, setNewProductPrice] = useState<number>(0);
	const [newProductImage, setNewProductImage] = useState<File | null>(null);
	const [newProductTags, setNewProductTags] = useState<string>(
		"anniversaire, naissance, mariage, baptême, fete-des-meres, saint-valentin,"
	);
	const [newProductPoids, setNewProductPoids] = useState<number>(0);
	const [errorMessage, setErrorMessage] =
		useState<string>("blakbflazerbpvgiab");
	const [display, setDisplay] = useState<string>("flex");
	const date = new Date(user.created_at).toLocaleDateString();
	const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
	const userName = useSelector((state: RootState) => state.user.firstName);
	const token = useSelector((state: RootState) => state.token.token);
	const [isAdmin, setIsAdmin] = useState<boolean>(false);
	const dispatch: Dispatch<AuthActionTypes> = useDispatch();
	const navigate = useNavigate();
	const [selectedTags, setSelectedTags] = useState<string[]>([]);
	
	const handleTagChange = (tag: string) => {
		setSelectedTags((prevTags) =>
			prevTags.includes(tag)
				? prevTags.filter((t) => t !== tag)
				: [...prevTags, tag]
		);
	};

	useEffect(() => {
		if (!token) {
			navigate("/login");
		}
	}, [token, navigate]);

	useEffect(() => {
		if (user.id === 1 || user.id === 2) {
			setIsAdmin(true);
		}
	}, [user]);

	let headersList = {
		Accept: "*/*",
		"Content-Type": "application/json",
	};

	let bodyContent = JSON.stringify({
		email: `${newEmail}`,
		firstName: `${newFirstName}`,
		lastName: `${newLastName}`,
		adresse: `${newAdresse}`,
		tel: `${newTel}`,
		id: `${user.id}`,
	});

	const handelSubmit = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api-candyshop/updateUser`,
			{
				method: "POST",
				headers: headersList,
				body: bodyContent,
			}
		);

		const data = await response.json();

		if (data.status !== 200) {
			setDisplay("flex");
			setErrorMessage(data.message);
		} else {
			const profileResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/api-candyshop/profile`,
				{
					method: "POST",
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);

			const profileData = await profileResponse.json();

			if (profileData.status === 200) {
				const newUser: UserState = {
					id: profileData.body.id,
					firstName: profileData.body.firstName,
					lastName: profileData.body.lastName,
					email: profileData.body.email,
					adresse: profileData.body.adresse,
					tel: profileData.body.tel,
					created_at: profileData.body.created_at,
				};
				dispatch(Login(newUser));
			}
		}
		setDisplay("none");
		window.location.reload();
	};

	// Création de l'objet FormData
	const formData = new FormData();
	formData.append("name", newProductName);
	formData.append("description", newProductDescription);
	formData.append("price", newProductPrice.toString());
	formData.append("tags", selectedTags.join(", "));
	formData.append("poids", newProductPoids.toString());

	if (newProductImage) {
		formData.append("image", newProductImage); // Ajouter le fichier
	}

	const createProduct = async (e: React.MouseEvent) => {
		e.preventDefault();

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api-candyshop/createProduct`,
			{
				method: "POST",
				headers: {
					Authorization: "Bearer " + token,
				},
				body: formData,
			}
		);
		const data = await response.json();

		if (data.status === 200) {
			window.location.reload();
		}
	};

	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.webp",
		logo: "https://candyshop-by-stess.fr/assets/icon.webp",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement candyshop by stess !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	return (
		<>
			<Helmet>
				<title>Mon compte - {userName}</title>
				<meta
					name="description"
					content={`Retrouvez et modifiez vos informations personnelles sur Candyshop. Votre compte, votre historique de commandes, et plus encore.`}
				/>
				<meta name="robots" content="noindex, nofollow" />
				<link rel="canonical" href="https://candyshop-by-stess.fr/user" />
				<script type="application/ld+json">
					{JSON.stringify(structuredData)}
				</script>
			</Helmet>
			<NavLink
				to={`/`}
				className="homePage-link-back homePage-link-back-gallerie"
				aria-label="Retour à l'accueil"
			>
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="userPage page">
				<h1> Bienvenue {userName} </h1>
				{isAdmin && (
					<div className="user-wrapper-admin">
						<div className="createProduct">
							<div className="createProduct-items">
								<label htmlFor="newProductName">Nom</label>
								<input
									type="text"
									value={newProductName}
									required={true}
									onChange={(e) => setNewProductName(e.target.value)}
									placeholder="Nom du produit"
								/>
							</div>
							<div className="createProduct-items">
								<label htmlFor="newProductDescription">Description</label>
								<textarea
									value={newProductDescription}
									required={true}
									onChange={(e) => setNewProductDescription(e.target.value)}
									placeholder="Description du produit"
									style={{ height: "170px" }}
								/>
							</div>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<div className="createProduct-items">
									<label htmlFor="newProductPrice">Prix</label>
									<input
										type="number"
										value={newProductPrice}
										required={true}
										step={0.01}
										onChange={(e) => setNewProductPrice(e.target.valueAsNumber)}
										placeholder="Prix du produit"
									/>
								</div>
								<div className="createProduct-items">
									<label htmlFor="newProductPoids">Poids en Kg</label>
									<input
										type="number"
										value={newProductPoids}
										required={true}
										step={0.001}
										onChange={(e) => setNewProductPoids(e.target.valueAsNumber)}
										placeholder="Poids du produit"
									/>
								</div>
							</div>
							<div className="createProduct-items">
								<label htmlFor="file">Image</label>
								<input
									type="file"
									accept="image/*"
									required={true}
									onChange={(e) => {
										setNewProductImage(e.target.files![0]);
									}}
									placeholder="Image du produit"
								/>
							</div>
							<div className="createProduct-items">
								<label htmlFor="newProductTags">Tags</label>
								<div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
									{predefinedTags.map((tag) => (
										<label
											key={tag}
											style={{
												display: "flex",
												marginBottom: "5px",
												width: "fit-content",
											}}
										>
											<input
												type="checkbox"
												checked={selectedTags.includes(tag)}
												onChange={() => handleTagChange(tag)}
											/>
											{tag}
										</label>
									))}{" "}
								</div>
							</div>
							<button className="create" onClick={createProduct}>creer un produit</button>
						</div>
						<div className="admin-button">
							<NavLink
								to={`/historique-commandes`}
								className="orders-link orders-link-admin"
							>
								Historique des commandes
							</NavLink>
							<button
								className="logout-button"
								onClick={() => {
									dispatch<any>(TokenOff());
									dispatch<any>(Logout());
									dispatch<any>(clearCart());
									window.location.replace("/");
								}}
								aria-label="Déconnection"
							>
								déconnection
							</button>
						</div>
					</div>
				)}
				{!isAdmin && (
					<div className="user-wrapper">
						<div>
							<NavLink to={`/historique-commandes`} className="orders-link">
								Historique des commandes
							</NavLink>
							<div className="user-info">
								<h2>Informations personnelles</h2>
								{isReadOnly && (
									<button
										className="edit-button"
										onClick={() => setIsReadOnly(!isReadOnly)}
										aria-label="Modifier"
									>
										Modifier
									</button>
								)}
								{!isReadOnly && (
									<button
										className="save-button"
										onClick={handelSubmit}
										aria-label="Sauvgarder"
									>
										sauvgarder
									</button>
								)}
								<div className="user-info-item">
									<p>Nom:</p>
									<input
										type="text"
										value={newLastName}
										readOnly={isReadOnly}
										onChange={(e) =>
											setNewLastName(e.target.value.toUpperCase())
										}
										aria-label="Nom"
									></input>
								</div>
								<div className="user-info-item">
									<p>Prenom:</p>
									<input
										type="text"
										value={newFirstName}
										readOnly={isReadOnly}
										onChange={(e) =>
											setNewFirstName(e.target.value.toUpperCase())
										}
										aria-label="Prenom"
									></input>
								</div>
								<div className="user-info-item">
									<p>Email:</p>
									<input
										type="text"
										value={newEmail}
										readOnly={isReadOnly}
										onChange={(e) => setNewEmail(e.target.value)}
										aria-label="Email"
									></input>
								</div>
								<div className="user-info-item">
									<p>Adresse:</p>
									<input
										type="text"
										value={newAdresse}
										readOnly={isReadOnly}
										onChange={(e) => setNewAdresse(e.target.value)}
										aria-label="Adresse"
									></input>
								</div>
								<div className="user-info-item">
									<p>Tel:</p>
									<input
										type="text"
										value={newTel}
										readOnly={isReadOnly}
										onChange={(e) => setNewTel(e.target.value)}
										aria-label="Téléphone"
									></input>
								</div>
								<div className="user-info-item date">
									<p>Date de creation:</p>
									<input
										type="text"
										readOnly
										value={date}
										aria-label="Date de creation"
									></input>
								</div>
							</div>
							<button
								className="logout-button"
								onClick={() => {
									dispatch<any>(TokenOff());
									dispatch<any>(Logout());
									dispatch<any>(clearCart());
									window.location.replace("/");
								}}
								aria-label="Déconnection"
							>
								déconnection
							</button>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
