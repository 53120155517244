// src/components/StripeCheckout.tsx
import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { clearCart } from "../../redux/actions/action";
import { useDispatch } from "react-redux";

import "./stripeCheckout.scss";
import { NavLink } from "react-router-dom";

interface StripeCheckoutProps {
	cart: any[]; // Liste des articles dans le panier
	user: {
		firstName: string;
		lastName: string;
		email: string;
		tel: string;
		adresse: string;
		id: number;
	};
}

const StripeCheckout: React.FC<StripeCheckoutProps> = ({ cart, user }) => {
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const [isClickandcollect, setIsClickandcollect] = useState(false);
	const [ports, setPorts] = useState<number>(0);
	const [ports2, setPorts2] = useState<number>(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<React.ReactNode | null>(null);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const width = window.innerWidth;


	useEffect(() => {
		if (!Array.isArray(cart) || cart.length === 0) {
			setPorts(0);
			return;
		}

		const shippingRates = [
			{ maxWeight: 0.5, cost: 7.35 },
			{ maxWeight: 0.75, cost: 8.65 },
			{ maxWeight: 1, cost: 9.4 },
			{ maxWeight: 2, cost: 10.7 },
			{ maxWeight: 5, cost: 16.6 },
			{ maxWeight: 10, cost: 24.2 },
			{ maxWeight: 15, cost: 30.55 },
			{ maxWeight: 30, cost: 37.85 },
		];

		const totalPoids = cart.reduce(
			(acc, item) => acc + item.poids * item.quantity,
			0.5
		);

		const rate = shippingRates.find((rate) => totalPoids <= rate.maxWeight) || {
			cost: 0,
		};

		if (rate.cost !== 0) {
			setPorts(rate.cost);
		} else {
			setError(
				`Votre commande est trop volumineuse pour un envoi postal.\n Veuillez nous contacter pour obtenir un devis.`
			); // Affichez un message d'erreur
		}
	}, [cart]);

	useEffect(() => {
		if (isClickandcollect === true) {
			setPorts2(0);
		} else {
			setPorts2(ports);
		}
	}, [isClickandcollect, ports]);

	// Fonction pour créer la session de paiement
	const handlePayment = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!isClickandcollect && ports <= 0) {
			setError(
				<span>
					Les frais de port ne peuvent pas être à 0. Veuillez nous contacter.
					<a href="mailto:candyshopbystess@outlook.fr">Envoyez un mail</a>
					{""}
				</span>
			);
			return;
		}
		if (!stripe || !elements) {
			return;
		}
		if (user.id === 0) {
			setError(
				<span>
					Veuillez vous connecter pour effectuer un paiement.{" "}
					<NavLink to="/login">Se connecter</NavLink>
				</span>
			);
			return;
		}

		setLoading(true);
		setError(null);
		setSuccessMessage(null);

		try {
			const baseUrl = process.env.REACT_APP_API_URL;

			const adresse = isClickandcollect ? "Click and Collect" : user.adresse;
			// Crée une session de paiement sur le backend
			const response = await axios.post(
				`${baseUrl}/api-candyshop/create-payment-intent`,
				{
					items: cart,
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					phone: user.tel,
					address: adresse,
					ports: ports2,
					userId: user.id,
					clickandcollect: isClickandcollect,
				}
			);

			const { clientSecret } = response.data;

			const cardElement = elements.getElement(CardElement);

			if (cardElement) {
				// Confirmer le paiement avec le clientSecret
				const { error, paymentIntent } = await stripe.confirmCardPayment(
					clientSecret,
					{
						payment_method: {
							card: cardElement,
							billing_details: {
								name: `${user.firstName} ${user.lastName}`,
								email: user.email,
								phone: user.tel,
								address: {
									line1: adresse,
								},
							},
						},
					}
				);
				if (error) {
					setError(
						error.message || "Une erreur est survenue lors du paiement."
					);
				} else if (paymentIntent && paymentIntent.status === "succeeded") {
					setSuccessMessage("Paiement réussi !");
					dispatch(clearCart());
					setTimeout(() => {
						window.location.replace("/");
					}, 3000);
				}
			}
		} catch (err) {
			setError("Une erreur est survenue lors du paiement.");
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<form className="commande-wrapper" onSubmit={handlePayment}>
			<div className="recap-commande">
				<h2>Récapitulatif de la commande</h2>
				<p className="recap-commande-total">
					<strong>Total: </strong>
					{cart
						.reduce((total, item) => total + item.price * item.quantity, ports2)
						.toFixed(2)}{" "}
					€
				</p>
				<p>
					{" "}
					<strong>Fraits de port:</strong>{" "}
					{isClickandcollect ? "Gratuit" : `${ports.toFixed(2) + " €"}`}
				</p>
				{cart.map((item, index) => (
					<div key={item.id} className="recap-commande-item">
						<div
							className="recap-commande-img"
							style={{
								backgroundImage: `url(${process.env.REACT_APP_API_URL}/api-candyshop/${item.image_path})`,
							}}
						></div>
						<div className="recap-commande-info">
							<p className="recap-commande-info-name">{item.name}</p>
							<p>
								{" "}
								<strong>Quantité:</strong> {item.quantity}
							</p>
							<p>
								{" "}
								<strong>Prix unitaire:</strong> {item.price.toFixed(2) + " €"}
							</p>
							<p>
								<strong>Total:</strong>{" "}
								{(item.price * item.quantity).toFixed(2) + " €"}
							</p>
						</div>
					</div>
				))}
			</div>
			<div className="payement-wrapper">
				<h3>Informations de facturation</h3>

				<div>
					<label>
						Prénom:
						<input type="text" value={user.firstName} readOnly />
					</label>
				</div>
				<div>
					<label>
						Nom:
						<input type="text" value={user.lastName} readOnly />
					</label>
				</div>
				<div>
					<label>
						Email:
						<input type="email" value={user.email} readOnly />
					</label>
				</div>
				<div>
					<label>
						Téléphone:
						<input type="tel" value={user.tel} readOnly />
					</label>
				</div>
				<div>
					<label>
						Adresse:
						<textarea
							value={
								isClickandcollect
									? "CR3A'TYPIK 1 rue de la gare, 86360 chasseneuil du poitou"
									: `${user.adresse}`
							}
							readOnly
						/>
					</label>
				</div>
				<div className="clickandcollect">
					<label>
						click&collect
						<input
							type="checkbox"
							checked={isClickandcollect}
							onChange={() => setIsClickandcollect(!isClickandcollect)}
						/>
					</label>
				</div>
				<h3>Informations de paiement</h3>
					<CardElement
						className="card-element"
						options={{
							hidePostalCode: true,
							disableLink: true,
							style: {
								base: {
									fontSize: width <= 885 ? "12px" : "16px",
									color: "#f850b3",
									"::placeholder": {
										color: "#f850b3",
									},
								},
								invalid: {
									color: "#9e2146",
								},
							},
						}}
					/>
				<button type="submit" disabled={!stripe || loading || ports === 0}>
					{ports === 0
						? "Panier vide, Payement impossible"
						: loading
						? "Chargement..."
						: "Payer"}
				</button>

				{error && <p className="error">{error}</p>}
				{successMessage && <p className="success">{successMessage}</p>}
			</div>
		</form>
	);
};

export default StripeCheckout;
