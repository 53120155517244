import React from "react";

import "./facebookReviews.scss";

export function FacebookReviews() {
	console.log(window.facebookLoaded);
	
	if (!window.facebookLoaded) return null;
	return (
		<div className="facebook-reviews">
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fjeremy.ayrault.86%2Fposts%2Fpfbid035qCZHTKcBaiyaUHUqgesq98oyuNvMFbkwqCh4GRYQronmCqRXAnRskidSfnJV1Ntl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0FWKj5Cwy1XvpV6zoQYiL2CJJhFQeHvMHf16eWY3QKh1pHgoLf4kRUQ7iZUWaKb3rl%26id%3D100077563069949&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0wNC6EFHwq1FPMeryy7mcrRYVdt7p4izusHEvFtgnNSSbTvURrK1Yjayt8APgZpJkl%26id%3D100072602747200&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fremi.carruttys.5%2Fposts%2Fpfbid02HGDTm5DXkRRMsUpKJhqu1Yj8ekMpRdiyWzcatueaTuHARcYCz2rr13PnPLcWfZ3hl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fom.florent%2Fposts%2Fpfbid014FBRw7Uhp3nWKHg6TsH2ytwvJ1M1uuLos5KdEnZFzoHwBZWZdHAFVaoguGJ8iuxl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Flea.desat.5%2Fposts%2Fpfbid09YnLSjKwoyjh8QTP5EU41AZrqu2CApZ8o5uWAyGX7sn4t2F2GZq1huMsDTbdQDQnl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fness.verbalbrolik%2Fposts%2Fpfbid038FKHzF1MSXpk6oAjh8BKRhYhPQUb8BHtzKbX7h1xEm2ai4BxzZBW6bFCd8c4cjNVl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmichele.fazilleau%2Fposts%2Fpfbid02Kz8hJ8ZeArBGTjVgMcVP6LhCtLnBh5LisqJazCy7XkV1Ja3acbbUcPJ2jztYynoil&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02tNsgFPdT59CqpDH5s1zEiPLZBmk8PsxJ5psTAS2vJTcpBC85cNmJKQJ1vmY34zLel%26id%3D100010042355216&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0ewt1cDeJHU7PGRTHdG3VNqPHGRjv95obzEqF8iyvEtMtscL4TJ1xTfzwqHAKA1TLl%26id%3D100077520441000&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0A3Ptjq4u3MQM2zCLvh2gctVVhuj5iRqoLztg2da4LbEL1ycqcVS4XXqpwxWhW28gl%26id%3D61570406710615&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmanue.merletchaillou%2Fposts%2Fpfbid02sTFbHt47F6Qpim28ZM6Ui2cXcBA7e4U5Xw1W6vNy4rLN6bwycNkdYbeLKx3TMivGl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FGermainBsn%2Fposts%2Fpfbid02UMa9rzMrmhsHpvNpYohs4kzS4mkc7MSXNkxgBRPD9xpXn1sqA6RxWtT48XGtwY4cl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fju.lien.39545%2Fposts%2Fpfbid0RPnWkBRS3CLm1sJdS2RqCDKtCHTQCh5Ep4cSyW3SduNXtusEeT1SM2SVr6NPAmTCl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fleblondfamily%2Fposts%2Fpfbid0UJjXNwdt4oSFNQHniXgVUqRXdPrsgUpnGJU8g5jad8ezoBLW2tf7dGWHuMRkFCsSl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02CMcYjgjkaRvPnhi4UvEgabBm7K9uVZCTQecCF18HjgSYQ4NN5DBZEefYvb3B98z9l%26id%3D61566115916751&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fdavid.lidon%2Fposts%2Fpfbid0oLC9EhUiKBGJ28NpRmq3CKvMpgRYjgr2GqUo7H2kf7Us9SJZtdSFkj9i1JTg1CNVl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcorentin.leveque.549%2Fposts%2Fpfbid0r4z3qNs2ANvdZDb7DVp2ZnKcPa7dNjKneJGn85SQG4zE77oBiTHYSS4tsbSCfBrel&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fthomas.baudry.71%2Fposts%2Fpfbid02wWGnXFtCw1s51vY8RhETFZE8oQUv47k2oRHRspDXUYwFA8xsqcwiXuWE9X1kLsDLl&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
			<iframe
				src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0NMJVZuQjdnowJthrYrGJYZp36Gs9f47Tz7QNbjsHCSzBU7s5DZ1rz3m94EFBGVm8l%26id%3D100091215686131&show_text=true&width=500"
				style={{ border: "none", overflow: "hidden" }}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				title="Facebook Post"
			></iframe>
		</div>
	);
}
