import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Cart } from "../../components/cart/cart";
import { Select } from "../../components/select/select";
import "./productPage.scss";
import { RootState } from "../../redux/actions/typeAction";
import Modal from "react-modal";

interface ProductSchema {
	id: number;
	name: string;
	description: string;
	price: number;
	image_path: string;
	tags: string;
	poids: number;
}

const predefinedTags = [
	"anniversaire",
	"naissance",
	"mariage",
	"baptême",
	"fete-des-meres",
	"saint-valentin",
	"chocolat",
	"chocolats",
	"chocolats-Page",
	"kinder",
	"bonbon",
	"vrac",
	"preparations",
	"gâteaux-Kinder",
	"gâteaux",
	"cookies",
	"sucre",
	"vegan",
	"halal",
	"assortiment",
	"vintage",
	"halloween",
	"pâques",
];

export function Product() {
	const params = useParams();
	const id = params.id;
	const category = params.category;
	const [product, setProduct] = useState<ProductSchema[]>([]);
	const currentProduct = product[0];
	const [selectedSucre, setSelectedSucre] = useState<string>("");
	const [idOption, setIdOption] = useState<number>(0);
	const [error, setError] = useState<string | null>(null);
	const [rateCost, setRateCost] = useState<number>(0);
	const [isAddToCart, setIsAddToCart] = useState<boolean>(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state: RootState) => state.user);
	const [isAdmin, setIsAdmin] = useState<boolean>(false);
	const [newDescription, setNewDescription] = useState<string>("");
	const [newPrice, setNewPrice] = useState<number>(0);
	const [newName, setNewName] = useState<string>("");
	const [newPoids, setNewPoids] = useState<number>(0);
	const [selectedTags, setSelectedTags] = useState<string[]>([]);
	const [openModale, setOpenModale] = useState<boolean>(false);
	const width = window.innerWidth;

	useEffect(() => {
		if (user.id === 1 || user.id === 2) {
			setIsAdmin(true);
		}
	}, [user]);

	useEffect(() => {
		const shippingRates = [
			{ maxWeight: 0.25, cost: 5.25 },
			{ maxWeight: 0.5, cost: 7.35 },
			{ maxWeight: 0.75, cost: 8.65 },
			{ maxWeight: 1, cost: 9.4 },
			{ maxWeight: 2, cost: 10.7 },
			{ maxWeight: 5, cost: 16.6 },
			{ maxWeight: 10, cost: 24.2 },
			{ maxWeight: 15, cost: 30.55 },
			{ maxWeight: 30, cost: 37.85 },
		];

		const rate = shippingRates.find(
			(rate) => currentProduct?.poids <= rate.maxWeight
		) || {
			cost: 0,
		};
		setRateCost(rate.cost);
	}, [currentProduct]);

	const handleSucreChange = (option: { value: number; title: string }) => {
		setSelectedSucre(option.title);
		setIdOption(option.value * 1000);
		setError(null); // Réinitialiser l'erreur à chaque requête
	};

	useEffect(() => {
		setError(null); // Réinitialiser l'erreur à chaque requête

		fetch(`${process.env.REACT_APP_API_URL}/api-candyshop/product/${id}`)
			.then((response) => {
				if (response.status !== 200) {
					navigate("/404");
				}
				return response.json();
			})
			.then((data) => {
				setProduct(data);
				setNewDescription(data[0].description);
				setNewPrice(data[0].price);
				setNewName(data[0].name);
				setSelectedTags(data[0].tags.split(", "));
				setNewPoids(data[0].poids);
			})
			.catch((err) => {
				setError(err.message);
			});
	}, [id, navigate]);

	const addToCart = () => {
		dispatch({
			type: "ADD_ITEM",
			payload: {
				id: product[0]?.id,
				name: product[0]?.name,
				price: product[0]?.price,
				quantity: 1,
				image_path: product[0]?.image_path,
				poids: product[0]?.poids,
			},
		});
		setIsAddToCart(true);
		setTimeout(() => {
			setIsAddToCart(false);
		}, 3000);
	};

	const addToCartSucre = () => {
		if (idOption === 0) {
			setError("Veuillez choisir un arôme.");
			return;
		}
		dispatch({
			type: "ADD_ITEM",
			payload: {
				id: product[0]?.id * idOption,
				name: `${product[0]?.name} arôme ${selectedSucre}`,
				price: product[0]?.price,
				quantity: 1,
				image_path: product[0]?.image_path,
				poids: product[0]?.poids,
			},
		});
		setIsAddToCart(true);
		setTimeout(() => {
			setIsAddToCart(false);
		}, 3000);
	};

	const handleTagChange = (tag: string) => {
		setSelectedTags((prevTags) =>
			prevTags.includes(tag)
				? prevTags.filter((t) => t !== tag)
				: [...prevTags, tag]
		);
	};

	const updateProduct = () => {
		fetch(`${process.env.REACT_APP_API_URL}/api-candyshop/product/${id}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				name: newName,
				description: newDescription,
				price: newPrice,
				tags: selectedTags.join(", "),
				poids: newPoids,
			}),
		})
			.then((response) => {
				if (response.status !== 200) {
					setError(
						"Une erreur est survenue lors de la modification du produit."
					);
				}
				return response.json();
			})
			.then((data) => {
				setProduct(data);
				window.location.reload();
			})
			.catch((err) => {
				setError(err.message);
			});
	};

	// Remplir les données structurées pour la liste de produits
	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.webp",
		logo: "https://candyshop-by-stess.fr/assets/icon.webp",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement candyshop by stess !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	const itemList = {
		"@context": "https://schema.org",
		"@type": "Product",
		name: currentProduct?.name,
		description: currentProduct?.description,
		image: `${process.env.REACT_APP_API_URL}/api-candyshop/${currentProduct?.image_path}`,
		offers: {
			"@type": "Offer",
			priceCurrency: "EUR",
			price: currentProduct?.price.toFixed(2),
			availability: "https://schema.org/InStock",
			priceValidUntil: "2025-12-31",
			shippingDetails: {
				"@type": "OfferShippingDetails",
				shippingRate: {
					"@type": "MonetaryAmount",
					value: rateCost.toFixed(2),
					currency: "EUR",
				},
				shippingDestination: {
					"@type": "DefinedRegion",
					addressCountry: "FR",
				},
				shippingWeight: {
					"@type": "QuantitativeValue",
					value: currentProduct?.poids,
					unitText: "kg",
				},
			},
			hasMerchantReturnPolicy: {
				"@type": "MerchantReturnPolicy",
				returnPolicyCategory: "https://schema.org/NoReturns",
				merchantReturnLink: "https://candyshop-by-stess.fr/conditions",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
		review: [
			{
				"@type": "Review",
				author: { "@type": "Person", name: "Sandrine Leblond" },
				datePublished: "2024-11-21",
				reviewBody:
					"Des créations absolument délicieuses, je recommande vivement candyshop by stess !",
				reviewRating: {
					"@type": "Rating",
					bestRating: "5",
					worstRating: "1",
					ratingValue: "5",
				},
			},
		],
		keywords: Array.isArray(currentProduct?.tags)
			? currentProduct.tags.join(", ")
			: "",
		url: `https://candyshop-by-stess.fr/${category}/${id}`,
	};

	if (!currentProduct) {
		return <div>Chargement des informations du produit...</div>;
	}

	return (
		<>
			<Helmet>
				<title>Candyshop By Stess {product[0].name}</title>
				<link
					rel="canonical"
					href={`https://candyshop-by-stess.fr/${category}/${id}`}
				/>
				<meta name="description" content={product[0].description} />
				{/* JSON-LD pour les moteurs de recherche */}
				<script type="application/ld+json">
					{JSON.stringify([structuredData, itemList])}
				</script>
			</Helmet>

			<NavLink
				to={category === "product" ? "/" : `/${category}`}
				className="homePage-link-back"
				aria-label="Retour à la page catégories"
			>
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="productPage page">
				<Cart />
				{width > 425 && (
					<Modal
						isOpen={openModale}
						onRequestClose={() => setOpenModale(false)}
						contentLabel="Exemple de Modal"
						style={{
							overlay: { zIndex: 1000, backgroundColor: "rgba(0, 0, 0, 0.5)" },
							content: {
								width: "70%",
								height: "85%",
								margin: "auto",
								padding: "20px",
								borderRadius: "10px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundImage: `url(${process.env.REACT_APP_API_URL}/api-candyshop/${product[0].image_path})`,
								backgroundSize: "contain",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								overflow: "hidden",
							},
						}}
					>
						<button
							onClick={() => setOpenModale(false)}
							style={{
								position: "absolute",
								top: "10px",
								right: "10px",
								borderRadius: "50%",
								backgroundColor: "#f850b3",
								border: "none",
								width: "40px",
								height: "40px",
								fontSize: "1.5rem",
								fontWeight: "bold",
								color: "white",
								cursor: "pointer",
							}}
						>
							X
						</button>
					</Modal>
				)}
				{isAdmin ? (
					<input
						type="text"
						name="name"
						id="name"
						value={newName}
						onChange={(e) => setNewName(e.target.value)}
						style={{
							textAlign: "center",
							fontSize: "1.3rem",
							margin: "40px 0",
							width: "84%",
						}}
					/>
				) : (
					<h1 className="productPage-title">{product[0].name}</h1>
				)}
				<div className="productPage-wrapper">
					<div
						className="productPage-wrapper-img"
						onClick={() => setOpenModale(true)}
					>
						<img
							className="productPage-img"
							src={`${process.env.REACT_APP_API_URL}/api-candyshop/${product[0].image_path}`}
							alt={`candyshop by stess | ${product[0].name}`}
						/>
						{width > 425 && <p>Cliquer sur l'image pour agrandir</p>}
					</div>
					<div className="productPage-wrapper-descripAndPrice">
						<div className="productPage-description">
							<h2>Description du produit</h2>
							{isAdmin ? (
								<textarea
									value={newDescription}
									onChange={(e) => setNewDescription(e.target.value)}
									style={{ fontSize: "1rem", width: "100%", height: "200px" }}
								/>
							) : (
								<p
									dangerouslySetInnerHTML={{ __html: product[0].description }}
								></p>
							)}
						</div>
						<div className="productPage-price">
							<div className="productPage-price-infos">
								<h3>Prix</h3>
								{isAdmin ? (
									<input
										type="number"
										name="price"
										id="price"
										value={newPrice}
										step={0.01}
										onChange={(e) => setNewPrice(Number(e.target.value))}
									/>
								) : (
									<p>{product[0].price.toFixed(2)} €</p>
								)}
							</div>
							{isAdmin && (
								<div className="productPage-price-infos">
									<h3>Poids en Kg</h3>
									<input
										type="number"
										value={newPoids}
										step={0.001}
										onChange={(e) => setNewPoids(Number(e.target.value))}
									/>
								</div>
							)}
							{category === "sucre" && (
								<div className="productPage-price-select">
									<label htmlFor="sucre">Choisir un produit</label>
									<Select
										title={selectedSucre || "Choisir un arôme"}
										groupe1={{
											titile: "Classic",
											options: [
												{ title: "vanille", value: 1 },
												{ title: "pomme d'amour", value: 2 },
												{ title: "chocolat & noisette", value: 3 },
												{ title: "fraise", value: 4 },
												{ title: "noix de coco", value: 5 },
												{ title: "framboise", value: 6 },
												{ title: "citron", value: 7 },
												{ title: "letchee", value: 8 },
												{ title: "cerise", value: 9 },
												{ title: "orange", value: 10 },
												{ title: "mangue", value: 11 },
												{ title: "kiwi", value: 12 },
												{ title: "myrtille", value: 13 },
												{ title: "raisin", value: 14 },
												{ title: "fruit de la passion", value: 15 },
												{ title: "pastèque", value: 16 },
												{ title: "pêche", value: 17 },
												{ title: "ananas", value: 18 },
												{ title: "barbe à papa", value: 19 },
												{ title: "abricot", value: 20 },
												{ title: "crème brulée", value: 21 },
												{ title: "violette", value: 22 },
												{ title: "cappuccino", value: 23 },
											],
										}}
										groupe2={{
											titile: "DeLuxe",
											options: [
												{ title: "tarte a la framboise", value: 19 },
												{ title: "pistache", value: 20 },
												{ title: "praline rose", value: 21 },
												{ title: "foret noire", value: 22 },
												{ title: "banane", value: 23 },
												{ title: "glace à la vanille", value: 24 },
											],
										}}
										onSelect={handleSucreChange}
									/>
								</div>
							)}
							<div className="productPage-price-action">
								<button
									onClick={category === "sucre" ? addToCartSucre : addToCart}
									style={isAddToCart ? { backgroundColor: "green" } : category === "sucre" && selectedSucre === "" ? { backgroundColor: "red" } : {}}
								>
									{
										category === "sucre" && selectedSucre === ""
											? "Choisir un arôme"
											: isAddToCart
										? "Article ajouté avec succes"
										: "Ajouter au panier"}
								</button>
								<p className="productPage-dispo">
									<i
										className="fa-solid fa-check"
										style={{ color: "#00ff2a" }}
									></i>{" "}
									disponible en click&collect
								</p>
						{error && <p className="error">{error}</p>}
							</div>
						</div>
						{isAdmin && (
							<div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
								{predefinedTags.map((tag) => (
									<label
										key={tag}
										style={{ display: "block", marginBottom: "5px" }}
									>
										<input
											type="checkbox"
											checked={selectedTags.includes(tag)}
											onChange={() => handleTagChange(tag)}
										/>
										{tag}
									</label>
								))}
							</div>
						)}
						{isAdmin && (
							<button
								onClick={updateProduct}
								style={{
									backgroundColor: "red",
									height: "40px",
									width: "100%",
									fontSize: "1.2rem",
								}}
							>
								Modifier
							</button>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
