import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import persistor from "./redux/reducers/store";
import Spinner from "./components/spinner/spinner";

import "./index.css";
import store from "./redux/reducers/store";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

function Root() {
	const [isHydrated, setIsHydrated] = useState(false);

	useEffect(() => {
		const persistorInstance = persistStore(persistor, {}, () => {
			setIsHydrated(true); // PersistGate a terminé de restaurer l'état.
		});
		return () => persistorInstance.pause(); // Nettoyage en cas de démontage.
	}, []);

	return (
		<Provider store={store}>
			{isHydrated ? (
				<App />
			) : (
				<>
					{/* Affiche le contenu principal pour les bots et humains même si PersistGate charge */}
					<App />
					<Spinner /> {/* Affiche un spinner pendant le chargement */}
				</>
			)}
		</Provider>
	);
}

root.render(<Root />);
