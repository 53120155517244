import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { Cart } from "../../components/cart/cart";
import "./homePage.scss";
import { FacebookReviews } from "../../components/facebookReviews/facebookReviews";

interface Products {
	description: string;
	id: number;
	image_path: string;
	name: string;
	poids: number;
	price: number;
	tags: string[];
}

export function HomePage() {
	const [allPhotos, setAllPhotos] = useState<string[]>([]);
	const [photo, setPhoto] = useState<string>(
		"assets/1740500272753-251878885.webp"
	);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const allPhotosShuffeled = allPhotos.sort(() => Math.random() - 0.5);
	const width = window.innerWidth;

	useEffect(() => {
		// Récupérer les produits depuis l'API
		fetch(`${process.env.REACT_APP_API_URL}/api-candyshop/products`)
			.then((response) => response.json())
			.then((data) => {
				const imagePaths = data.map((product: Products) => product.image_path);
				setAllPhotos(imagePaths);
				if (imagePaths.length > 0) setPhoto(imagePaths[0]);
			});
	}, []);

	useEffect(() => {
		// Créer un carrousel uniquement lorsque `allPhotos` est défini
		if (allPhotosShuffeled.length > 0) {
			let i = 0;
			intervalRef.current = setInterval(() => {
				setPhoto(allPhotosShuffeled[i]);
				i = (i + 1) % allPhotosShuffeled.length; // Boucle circulaire
			}, 1500);

			// Nettoyer l'intervalle à la fin du cycle de vie
			return () => {
				if (intervalRef.current) {
					clearInterval(intervalRef.current);
				}
			};
		}
	}, [allPhotos, allPhotosShuffeled]);

	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.webp",
		logo: "https://candyshop-by-stess.fr/assets/icon.webp",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement candyshop by stess !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	const itemList = {
		"@context": "http://schema.org",
		"@type": "ItemList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "gallerie photos",
				url: "https://candyshop-by-stess.fr/gallerie-photos",
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "saisonnier",
				url: "https://candyshop-by-stess.fr/pâques",
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "configurateur",
				url: "https://candyshop-by-stess.fr/configurateur",
			},
			{
				"@type": "ListItem",
				position: 4,
				name: "gâteau",
				url: "https://candyshop-by-stess.fr/gateau",
			},
			{
				"@type": "ListItem",
				position: 5,
				name: "assortiment",
				url: "https://candyshop-by-stess.fr/assortiment",
			},
			{
				"@type": "ListItem",
				position: 6,
				name: "chocolats",
				url: "https://candyshop-by-stess.fr/chocolats-Page",
			},
			{
				"@type": "ListItem",
				position: 7,
				name: "sucre",
				url: "https://candyshop-by-stess.fr/sucre",
			},
			{
				"@type": "ListItem",
				position: 8,
				name: "preparations",
				url: "https://candyshop-by-stess.fr/preparations",
			},
			{
				"@type": "ListItem",
				position: 9,
				name: "bonbon en vrac",
				url: "https://candyshop-by-stess.fr/vrac",
			},
			{
				"@type": "ListItem",
				position: 10,
				name: "séléction VEGAN",
				url: "https://candyshop-by-stess.fr/vegan",
			},
		],
	};

	return (
		<>
			<Helmet>
				<title>Candyshop By Stess</title>
				<meta
					name="description"
					content="Candyshop by Stess : créations sucrées artisanales. Découvrez cônes, gâteaux et assortiments gourmands faits maison. Commandez vos douceurs maintenant!"
				/>
				<link rel="canonical" href={`https://candyshop-by-stess.fr/`} />
				<script type="application/ld+json">
					{JSON.stringify([structuredData, itemList])}
				</script>
			</Helmet>
			<div className="homePage page">
				<Cart />
				<h1>Candyshop By Stess: créations sucrées artisanales</h1>
				<div className="homePage-wrapper">
					<div>
						<NavLink
							to="/galerie-photos"
							className="homePage-wrapper-link gallerie-homePage"
							aria-labelledby="galerie-title"
						>
							{photo && (
								<div
									className="homePage-wrapper-link-photo"
									style={{
										backgroundImage: `url(${process.env.REACT_APP_API_URL}/api-candyshop/${photo})`,
									}}
								></div>
							)}
							<h3 id="galerie-title">Galerie photos</h3>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/pâques"
							className="homePage-wrapper-link brochette-homePage"
							aria-labelledby="paques-title"
						>
							<div
								className="homePage-wrapper-link-photo"
								style={{
									backgroundImage:
										width <= 425
											? `url(assets/paques-425.webp)`
											: width > 425 && width <= 768
											? `url(assets/paques-768.webp)`
											: width > 768 && width <= 1024
											? `url(assets/paques-1024.webp)`
											: `url(assets/paques-1440.webp)`,
								}}
							></div>
							<h3 id="paques-title">Pâques 2025</h3>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/configurateur"
							className="homePage-wrapper-link brochette-homePage"
							aria-labelledby="configurateur-title"
						>
							<div
								className="homePage-wrapper-link-photo"
								style={{
									backgroundImage:
										width <= 425
											? `url(assets/configurateur-425.webp)`
											: width > 425 && width <= 768
											? `url(assets/configurateur-768.webp)`
											: width > 768 && width <= 1024
											? `url(assets/configurateur-1024.webp)`
											: `url(assets/configurateur-1440.webp)`,
								}}
							></div>
							<h3 id="configurateur-title">Créez vos produits personnalisés</h3>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/gâteaux-Kinder"
							className="homePage-wrapper-link cake-homePage"
							aria-labelledby="gateau-title"
						>
							<div
								className="homePage-wrapper-link-photo"
								style={{
									backgroundImage:
										width <= 425
											? `url(assets/gateau-kinder-3-425.webp)`
											: width > 425 && width <= 768
											? `url(assets/gateau-kinder-3-768.webp)`
											: width > 768 && width <= 1024
											? `url(assets/gateau-kinder-3-1024.webp)`
											: `url(assets/gateau-kinder-3-1440.webp)`,
								}}
							></div>
							<h2 id="gateau-title">Les Gâteaux Kinder</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/assortiment"
							className="homePage-wrapper-link box-homePage"
							aria-labelledby="assortiment-title"
						>
							<div
								className="homePage-wrapper-link-photo"
								style={{
									backgroundImage:
										width <= 425
											? `url(assets/boite-grande-homePage-2-425.webp)`
											: width > 425 && width <= 768
											? `url(assets/boite-grande-homePage-2-768.webp)`
											: width > 768 && width <= 1024
											? `url(assets/boite-grande-homePage-2-1024.webp)`
											: `url(assets/boite-grande-homePage-2-1440.webp)`,
								}}
							></div>
							<h2 id="assortiment-title">Les Assortiments</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/chocolats-Page"
							className="homePage-wrapper-link chocolat-homePage"
							aria-labelledby="chocolats-page-title"
						>
							<div
								className="homePage-wrapper-link-photo"
								style={{
									backgroundImage:
										width <= 425
											? `url(assets/chocolats-425.webp)`
											: width > 425 && width <= 768
											? `url(assets/chocolats-768.webp)`
											: width > 768 && width <= 1024
											? `url(assets/chocolats-1024.webp)`
											: `url(assets/chocolats-1440.webp)`,
								}}
							></div>
							<h2 id="chocolats-page-title">Les Chocolats</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/sucre"
							className="homePage-wrapper-link sucre-homePage"
							aria-labelledby="sucre-title"
						>
							<div
								className="homePage-wrapper-link-photo"
								style={{
									backgroundImage:
										width <= 425
											? `url(assets/sucre-425.webp)`
											: width > 425 && width <= 768
											? `url(assets/sucre-768.webp)`
											: width > 768 && width <= 1024
											? `url(assets/sucre-1024.webp)`
											: `url(assets/sucre-1440.webp)`,
								}}
							></div>
							<h2 id="sucre-title">Les Sucres aromatisés</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/preparations"
							className="homePage-wrapper-link preparations-homePage"
							aria-labelledby="preparations-title"
						>
							<div
								className="homePage-wrapper-link-photo"
								style={{
									backgroundImage:
										width <= 425
											? `url(assets/prepa-425.webp)`
											: width > 425 && width <= 768
											? `url(assets/prepa-768.webp)`
											: width > 768 && width <= 1024
											? `url(assets/prepa-1024.webp)`
											: `url(assets/prepa-1440.webp)`,
								}}
							></div>
							<h2 id="preparations-title">Les Préparations de gâteaux</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/vrac"
							className="homePage-wrapper-link vrac-homePage"
							aria-labelledby="vrac-title"
						>
							<div
								className="homePage-wrapper-link-photo"
								style={{
									backgroundImage:
										width <= 425
											? `url(assets/vrac-425.webp)`
											: width > 425 && width <= 768
											? `url(assets/vrac-768.webp)`
											: width > 768 && width <= 1024
											? `url(assets/vrac-1024.webp)`
											: `url(assets/vrac-1440.webp)`,
								}}
							></div>
							<h2 id="vrac-title">Les Bonbons en Vrac</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/vegan"
							className="homePage-wrapper-link vegan-homePage"
							aria-labelledby="vegan-title"
						>
							<div
								className="homePage-wrapper-link-photo"
								style={{
									backgroundImage:
										width <= 425
											? `url(assets/vegan-425.webp)`
											: width > 425 && width <= 768
											? `url(assets/vegan-768.webp)`
											: width > 768 && width <= 1024
											? `url(assets/vegan-1024.webp)`
											: `url(assets/vegan-1440.webp)`,
								}}
							></div>
							<h2 id="vegan-title">Séléction Vegan</h2>
						</NavLink>
					</div>
				</div>
				<FacebookReviews />
			</div>
		</>
	);
}
