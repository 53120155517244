import React from "react";

export function Cookies() {
	return (
		<div>
			<h1>Politique de Cookies</h1>
			<p>
				Chez candyshop by stess, nous utilisons des cookies pour améliorer votre
				expérience sur notre site et pour analyser l'utilisation du site.
			</p>
			<h2>1. Qu'est-ce qu'un cookie ?</h2>
			<p>
				Un cookie est un fichier texte qui est déposé sur votre appareil lorsque
				vous visitez un site web. Il permet de collecter des informations sur
				votre navigation.
			</p>
			<h2>2. Les cookies que nous utilisons</h2>
			<p>
				Nous utilisons plusieurs types de cookies, y compris des cookies de
				performance pour analyser l'utilisation du site (par exemple, Google
				Analytics), et des cookies publicitaires pour personnaliser les
				annonces.
			</p>
			<h2>3. Comment contrôler les cookies</h2>
			<p>
				Vous pouvez gérer vos préférences en matière de cookies en cliquant sur
				le bouton "Gérer les préférences" dans la bannière de consentement.
			</p>
			<h2>4. Consentement</h2>
			<p>
				En utilisant notre site, vous acceptez l'utilisation de cookies
				conformément à cette politique. Si vous ne souhaitez pas accepter
				l'utilisation des cookies, vous pouvez les désactiver via les paramètres
				de votre navigateur.
			</p>
		</div>
	);
}
