import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/actions/typeAction";
import { updateQuantity, removeItem } from "../../redux/actions/action";
import "./cart.scss";
import { NavLink } from "react-router-dom";

export function Cart() {
	const dispatch = useDispatch();
	const cart = useSelector((state: RootState) => state.cart.items);
	const [isPanierOpen, setIsPanierOpen] = useState(false);
	const removeFromCart = (id: number) => {
		dispatch(removeItem(id));
	};

	const updateQuantities = (id: number, quantity: number) => {
		dispatch(updateQuantity(id, quantity));
	};

		
	useEffect(() => {
		const handleResize = () => {
			// Vérifier si un input est en focus
			const activeElement = document.activeElement;
			if (activeElement && (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA")) {
				return;
			}
	
			// Appliquer la logique habituelle sinon
			setIsPanierOpen(window.innerWidth > 768);
		};
	
		window.addEventListener("resize", handleResize);
		handleResize();
	
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	


	if (!isPanierOpen) {
		return (
			<button className="openCart" onClick={() => setIsPanierOpen(true)}>
				<i className="fa-solid fa-cart-shopping"></i> {cart.length}
			</button>
		);
	} else
		return (
			<aside className="cart">
				<button className="closeCart">
					<i
						className="fa-solid fa-xmark"
						onClick={() => setIsPanierOpen(false)}
					></i>
				</button>
				<h2>
					Votre Panier <i className="fa-solid fa-cart-shopping"></i>
				</h2>
				<h3>
					Total:{" "}
					{cart
						.reduce((total, item) => total + item.price * item.quantity, 0)
						.toFixed(2)}{" "}
					€
				</h3>
				<NavLink className="validerPanier" to={"/commande"}>
					valider le panier
				</NavLink>
				{cart.length === 0 ? (
					<p>Votre panier est vide.</p>
				) : (
					<ul>
						{[...cart].reverse().map((item) => (
							<li key={item.id}>
								<img
									src={`${process.env.REACT_APP_API_URL}/api-candyshop/${item.image_path}`}
									alt={item.name}
								/>
								<div>
									<h4>{item.name}</h4>
									<p>Prix: {item.price.toFixed(2)} €</p>
									<p className="quantity">
										Quantité:
										<input
											type="number"
											value={item.quantity}
											min={1}
											step={1}
											aria-label="Quantité"
											onChange={(e) =>
												updateQuantities(item.id, parseInt(e.target.value))
											}
										/>
									</p>
									<button onClick={() => removeFromCart(item.id)}>
										Supprimer
									</button>
								</div>
							</li>
						))}
					</ul>
				)}
			</aside>
		);
}
