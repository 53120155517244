import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import StripeCheckout from "../../components/stripeCheckout/stripeCheckout";
import { RootState } from "../../redux/actions/typeAction";

import "./commande.scss";


// const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

if (!publishableKey) {
	throw new Error(
		"La clé publique Stripe n'est pas définie dans les variables d'environnement."
	);
}

const stripePromise = loadStripe(publishableKey);

export function Commande() {
	const cart = useSelector((state: RootState) => state.cart.items);
	const user = useSelector((state: RootState) => state.user);

	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop by stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.webp",
		logo: "https://candyshop-by-stess.fr/assets/icon.webp",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement candyshop by stess !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};


	return (
		<>
		<Helmet>
				<title>valider votre commande sur candyshop by stess</title>
				<meta
					name="description"
					content="valider votre commande sur candyshop by stess"
				/>
				<meta name="robots" content="noindex, nofollow"/>
				<link rel="canonical" href="https://candyshop-by-stess.fr/commandes" />
				<script type="application/ld+json">
					{JSON.stringify(structuredData)}
				</script>

			</Helmet>
			<NavLink to="/" className="homePage-link-back" aria-label="Retour à l'accueil">
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<NavLink to="/conditions" className="conditions-link" aria-label="condition de vente et retour">Conditions de vente et retour</NavLink>
			<Elements stripe={stripePromise}>
      <div className="commande page">
        <h1>Page de commande</h1>
        <StripeCheckout cart={cart} user={user} />
      </div>
    </Elements>
		</>
	);
}

export default Commande;
