import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Dispatch } from "redux";
import { Login, TokenOn } from "../../redux/actions/action";
import { AuthActionTypes, UserState } from "../../redux/actions/typeAction";

import { Helmet } from "react-helmet";
import "./loginPage.scss";

export function LoginPage() {
	const [login, setLogin] = useState<boolean>(true);
	const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [newLastName, setNewLastName] = useState<string>("");
	const [newFirstName, setNewFirstName] = useState<string>("");
	const [newEmail, setNewEmail] = useState<string>("");
	const [newAdresse, setNewAdresse] = useState("");
	const [newPostalCode, setNewPostalCode] = useState("");
	const [newCity, setNewCity] = useState("");
	const [newTel, setNewTel] = useState<string>("");
	const [newPassword, setNewPassword] = useState<string>("");
	const [newPasswordConfirmed, setNewPasswordConfirmed] = useState<string>("");
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [display, setDisplay] = useState<string>("none");
	const dispatch: Dispatch<AuthActionTypes> = useDispatch();
	const fullAddress = `${newAdresse}, ${newPostalCode} ${newCity}`;
	const width = window.innerWidth;

	const handelSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const loginData = await fetch(
			`${process.env.REACT_APP_API_URL}/api-candyshop/login`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					email: email,
					password: password,
				}),
			}
		);
		const loginDataJson = await loginData.json();

		if (loginDataJson.status === 200) {
			dispatch(TokenOn(loginDataJson.token));

			const userDataFetched = await fetch(
				`${process.env.REACT_APP_API_URL}/api-candyshop/profile`,
				{
					method: "POST",
					headers: {
						Authorization: "Bearer " + loginDataJson.token,
					},
				}
			);
			const userDataJson = await userDataFetched.json();
			const userData: UserState = {
				id: userDataJson.body.id,
				lastName: userDataJson.body.lastName,
				firstName: userDataJson.body.firstName,
				email: userDataJson.body.email,
				adresse: userDataJson.body.adresse,
				created_at: userDataJson.body.createdAt,
				tel: userDataJson.body.tel,
			};
			dispatch(Login(userData));

			window.location.href = "/";
		}
	};

	let headersList = {
		Accept: "*/*",
		"Content-Type": "application/json",
	};

	let bodyContent = JSON.stringify({
		email: `${newEmail}`,
		password: `${newPassword}`,
		firstName: `${newFirstName}`,
		lastName: `${newLastName}`,
		adresse: `${fullAddress}`,
		tel: `${newTel}`,
		created_at: `${new Date().getTime()}`,
	});

	const handelSubmitSignup = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (newPassword !== newPasswordConfirmed) {
			setErrorMessage("Le mot de passe n'est pas correct! ");
			setDisplay("flex");
		}
		if (newPassword === newPasswordConfirmed) {
			fetch(`${process.env.REACT_APP_API_URL}/api-candyshop/signup`, {
				method: "POST",
				headers: headersList,
				body: bodyContent,
			})
				.then((response) => response.json())
				.then(async (response) => {
					if (response.status !== 200) {
						setDisplay("flex");
						setErrorMessage(response.message);
					}
					if (response.status === 200) {
						const data = await response;
						setDisplay("none");
						window.location.replace("./login");
					}
				});
		}
	};

	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.webp",
		logo: "https://candyshop-by-stess.fr/assets/icon.webp",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	return (
		<>
			<Helmet>
				<title>Connexion - Créez ou accédez à votre compte</title>
				<meta
					name="description"
					content="candyshop by stess | Accédez à votre compte ou créez-en un nouveau pour profiter de nos services."
				/>
				<meta name="robots" content="noindex, nofollow" />
				<link rel="canonical" href="https://candyshop-by-stess.fr/login" />
				<script type="application/ld+json">
					{JSON.stringify(structuredData)}
				</script>
			</Helmet>
			<NavLink
				to={`/`}
				className="homePage-link-back homePage-link-back-gallerie"
				aria-label="Retour à l'accueil"
			>
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="loginPage page">
				<h1>Connexion</h1>
				<div className="login-wrapper">
					<div className="login-form">
						<h2>connexion</h2>
						<form onSubmit={handelSubmit}>
							<label htmlFor="email" className="email">
								Adresse email
								<input
									type="text"
									placeholder="adresse email"
									autoComplete="email"
									required
									onChange={(e) => setEmail(e.target.value)}
									aria-label="email"
								/>
							</label>
							<label htmlFor="password" className="password">
								Mot de passe
								<div className="password-input">
									<input
										type={isPasswordVisible ? "text" : "password"}
										autoComplete="current-password"
										placeholder="Mot de passe"
										required
										onChange={(e) => setPassword(e.target.value)}
										aria-label="password"
									/>
									<button
										type="button"
										onClick={() => {
											setIsPasswordVisible(!isPasswordVisible);
										}}
									>
										{" "}
										<i className="fa-solid fa-eye"></i>
									</button>
								</div>
							</label>
							<button type="submit">Connexion</button>
						</form>
					</div>
					<div className="signup-form">
						<h2>Créer un compte</h2>
						<form onSubmit={handelSubmitSignup}>
							<div>
								<label htmlFor="lastName" className="lastName">
									{width > 768 ? "Nom" : ""}
									<input
										type="text"
										autoComplete="family-name"
										placeholder="Nom"
										required
										onChange={(e) => setNewLastName(e.target.value)}
										aria-label="lastName"
									/>
								</label>
								<label htmlFor="firstName" className="firstName">
									{width > 768 ? "Prénom" : ""}
									<input
										type="text"
										autoComplete="given-name"
										placeholder="Prénom"
										required
										onChange={(e) => setNewFirstName(e.target.value)}
										aria-label="firstName"
									/>
								</label>
								<label htmlFor="email" className="email">
									{width > 768 ? "Email" : ""}
									<input
										type="text"
										placeholder="Email"
										autoComplete="email"
										required
										onChange={(e) => setNewEmail(e.target.value)}
										aria-label="email"
									/>
								</label>
							</div>
							<div>
								<label htmlFor="adresse" className="adresse">
									{width > 768 ? "Adresse" : ""}
									<input
										type="text"
										name="street-address"
										placeholder="Adresse"
										autoComplete="street-address"
										required
										onChange={(e) => setNewAdresse(e.target.value)}
										aria-label="adresse"
									/>
								</label>
								<label htmlFor="code-postal" className="code-postal">
									<input
										type="text"
										name="postal-code"
										placeholder="CP"
										autoComplete="postal-code"
										required
										onChange={(e) => setNewPostalCode(e.target.value)}
										aria-label="code-postal"
									/>
								</label>
								<label htmlFor="ville" className="ville">
									<input
										type="text"
										name="locality"
										placeholder="Ville"
										autoComplete="address-level2"
										required
										onChange={(e) => setNewCity(e.target.value)}
										aria-label="ville"
									/>
								</label>{" "}
								<label htmlFor="telephone" className="telephone">
									{width > 768 ? "Téléphone" : ""}
									<input
										type="text"
										placeholder="Téléphone"
										autoComplete="phone"
										required
										onChange={(e) => setNewTel(e.target.value)}
										aria-label="telephone"
									/>
								</label>
							</div>
							<label htmlFor="newPassword" className="newPassword">
								{width > 768 ? "Mot de passe" : ""}
								<input
									type="password"
									autoComplete="current-password"
									placeholder="Mot de passe"
									required
									onChange={(e) => setNewPassword(e.target.value)}
									aria-label="Password"
								/>
							</label>
							<label htmlFor="newPassword" className="newPassword confirmed">
								{width > 768 ? "Confirmez mot de passe" : ""}
								<input
									type="password"
									placeholder="Confirmez le mot de passe"
									required
									onChange={(e) => setNewPasswordConfirmed(e.target.value)}
									aria-label="confirmez Password"
								/>
							</label>
							<div className="signup-btn">
								<button type="submit">Inscription</button>
							</div>
						</form>
					</div>
					{width > 768 && (
						<div className={`slider slider${login ? "-right" : "-left"}`}>
							<button onClick={() => setLogin(!login)} aria-label="slider">
								{login ? "inscription" : "connexion"}
							</button>
						</div>
					)}
					{width <= 768 && (
						<div className={`slider slider${login ? "-bottom" : "-top"}`}>
							<button onClick={() => setLogin(!login)} aria-label="slider">
								{login ? "inscription" : "connexion"}
							</button>
						</div>
					)}
				</div>
				<p className="errorMessage" style={{ display: `${display}` }}>
					{errorMessage}
				</p>
			</div>
		</>
	);
}
